import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import '../styles/portfolio.css'
import PortfolioHeroSection from '../components/PortfolioComponents/PortfolioHeroSection'
import PortfolioDetailedAssetView from '../components/PortfolioComponents/PortfolioDetailedAssetView'

const pageTitle = 'Overmoon | Portfolio'
const pageDescription = `Discover diversification, institutional scale, and passive ownership through the Overmoon 721 Fund.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const Portfolio = () => {
  const { getPropertySummaryDetails } = useStaticQuery(graphql`
    query {
      getPropertySummaryDetails {
        propertyDetails {
          address {
            city
            formattedStreet
            formattedAddress
            state
            unit
            zipcode
            primaryPhotoUrl
            photo2
            photo3
            photo4
            photo5
          }
          baths
          beds
          propertyType
          sqft
          yearBuilt
          annualGrossRevenue
          index
        }
        processedImages {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
          }
        }
      }
    }
  `)

  const { propertyDetails, processedImages } = getPropertySummaryDetails

  return (
    <PageWrapper title={pageTitle} trackingName="portfolio">
      <PortfolioHeroSection propertyCount={propertyDetails.length.toString()} />
      <PortfolioDetailedAssetView
        assets={propertyDetails}
        processedImages={processedImages}
      />
    </PageWrapper>
  )
}

export default Portfolio
