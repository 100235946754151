import { useMediaQuery, useTheme } from '@mui/material'
import { OvermoonAssetViewCardProps } from './assetViewCardTypes'

const useOvermoonAssetViewCard = (props: OvermoonAssetViewCardProps) => {
  // Presentational layout hooks
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  return {
    isMobile,
    isTablet,

    ...props,
  }
}

export default useOvermoonAssetViewCard
