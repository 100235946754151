import React from 'react'
import OvermoonAssetViewCardDesktop from './AssetViewCardDesktop'
import OvermoonAssetViewCardTablet from './AssetViewCardTablet'
import OvermoonAssetViewCardMobile from './AssetViewCardMobile'
import useOvermoonAssetViewCard from './useAssetViewCard'
import { OvermoonAssetViewCardProps } from './assetViewCardTypes'

const OvermoonAssetViewCard = (props: OvermoonAssetViewCardProps) => {
  const presentationalProps = useOvermoonAssetViewCard(props)

  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <OvermoonAssetViewCardMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <OvermoonAssetViewCardTablet {...presentationalProps} />
  }
  return <OvermoonAssetViewCardDesktop {...presentationalProps} />
}

export default OvermoonAssetViewCard
