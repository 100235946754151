import { Core_PropertySummaryDetails } from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  Box,
  Grid,
  Pagination,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import theme from '@flock/shared-ui/src/theme/theme'
import { OvermoonAssetViewCard } from '@flock/shared-ui'
import SectionLayout from '../SharedComponents/SectionLayout'
import { abbreviationToState } from '../utils'

type PortfolioDetailedAssetViewProps = {
  assets: any[]
  processedImages: any[]
}

type CityCount = {
  [city: string]: number
}

type IndividualCityCount = {
  city: string
  count: number
}

const ASSETS_PER_PAGE = 3

const PortfolioDetailedAssetView = (props: PortfolioDetailedAssetViewProps) => {
  const { assets, processedImages } = props

  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [filteredPropertyAssets, setFilteredPropertyAssets] = useState<
    any[] | undefined
  >(assets)

  const [selectedMarket, setSelectedMarket] = useState('All')
  const [page, setPage] = useState<number>(1)

  const handleChangePage = (_event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
  }

  const citiesWithCountsObject = assets.reduce((total: CityCount, asset) => {
    const city = asset.address?.city || ''
    const curr = { ...total }
    curr[city] = total[city] || 0
    curr[city] += 1
    return curr
  }, {} as CityCount)

  const tempCitiesWithCountsList: IndividualCityCount[] = []

  Object.keys(citiesWithCountsObject).map((key) =>
    tempCitiesWithCountsList.push({
      city: key,
      count: citiesWithCountsObject[key],
    })
  )

  tempCitiesWithCountsList.sort((a, b) => (a.count > b.count ? -1 : 1))
  tempCitiesWithCountsList.unshift({ city: 'All', count: assets.length })

  useEffect(() => {
    let tempFilteredPropertyAssets: Core_PropertySummaryDetails[] | undefined
    if (selectedMarket === 'All') {
      tempFilteredPropertyAssets = assets
    } else {
      tempFilteredPropertyAssets = assets?.filter(
        (asset: any) => asset.address?.city === selectedMarket
      )
    }

    // Adding blurred property
    tempFilteredPropertyAssets.concat(tempFilteredPropertyAssets[0])

    setFilteredPropertyAssets(tempFilteredPropertyAssets)
    setPage(1)
  }, [selectedMarket, assets])

  return (
    <SectionLayout
      name="portfolio-detailed-asset-view"
      backgroundColor="gray1.main"
      sidePadding="0px"
    >
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="40px"
      >
        <Typography variant="h2" fontWeight={500} color="midnightBlue.main">
          Overmoon Owned Homes
        </Typography>
        <Typography variant="c1" color="disclaimer.main">
          Homes shown are owned and operated by Overmoon, but are not part of
          the Overmoon 721 Fund 1.
        </Typography>
      </Grid>
      {!isMobile && !isTablet && (
        <Grid
          item
          xs={3}
          display="flex"
          flexDirection="column"
          sx={{ paddingBottom: '64px' }}
        >
          <Box display="flex" flexDirection="column" width="100%" gap={1}>
            {tempCitiesWithCountsList.map((data) => (
              <Paper
                key={data.city}
                sx={{
                  padding: '16px',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                  borderRadius: '16px',
                  boxShadow: 'none',
                  backgroundColor:
                    selectedMarket === data.city
                      ? 'mulberry.main'
                      : 'gray1.main',
                  transition: 'background-color 0.2s ease-in-out',
                  cursor: 'pointer',
                }}
                onClick={() => setSelectedMarket(data.city)}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Typography
                    variant="p1"
                    fontWeight={selectedMarket === data.city ? 500 : 400}
                    color={
                      selectedMarket === data.city ? 'white.main' : undefined
                    }
                  >
                    {abbreviationToState[data.city] || data.city}
                  </Typography>
                  <Typography
                    variant="p3"
                    fontWeight={selectedMarket === data.city ? 500 : 400}
                    color={
                      selectedMarket === data.city ? 'white.main' : undefined
                    }
                  >
                    {data.count}
                  </Typography>
                </Box>
              </Paper>
            ))}
          </Box>
        </Grid>
      )}
      <Grid
        item
        xs={isMobile || isTablet ? 12 : 9}
        display="flex"
        flexDirection="column"
        sx={{ paddingBottom: '64px' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
          gap="16px"
        >
          {filteredPropertyAssets
            ?.slice(
              (page - 1) * ASSETS_PER_PAGE,
              (page - 1) * ASSETS_PER_PAGE + ASSETS_PER_PAGE
            )
            .map((asset) => (
              <OvermoonAssetViewCard
                asset={{
                  street: asset.address?.formattedStreet!,
                  city: asset.address?.city!,
                  state: asset.address?.state!,
                  primaryPhotoUrl: asset.address?.primaryPhotoUrl!,
                  photo2: asset.address?.photo2!,
                  photo3: asset.address?.photo3!,
                  photo4: asset.address?.photo4!,
                  photo5: asset.address?.photo5!,
                  annualGrossRevenue: asset.annualGrossRevenue!,
                  sqft: asset.sqft!,
                  yearBuilt: asset.yearBuilt!,
                  bed: asset.beds!,
                  bath: asset.baths!,
                  formattedAddress: asset.address?.formattedAddress!,
                  latitude: asset.address.latitude!,
                  longitude: asset.address.longitude!,
                }}
                processedImages={processedImages.slice(
                  asset.index * 5,
                  asset.index * 5 + 5
                )}
              />
            ))}
          {/* {filteredPropertyAssets!.length / ASSETS_PER_PAGE <= page && (
            <OvermoonAssetViewCard
              asset={{
                street: filteredPropertyAssets![0].address?.formattedStreet!,
                city: filteredPropertyAssets![0].address?.city!,
                state: filteredPropertyAssets![0].address?.state!,
                primaryPhotoUrl:
                  filteredPropertyAssets![0].address?.primaryPhotoUrl!,
                nightlyRate: filteredPropertyAssets![0].monthlyRentCents!,
                sqft: filteredPropertyAssets![0].sqft!,
                yearBuilt: filteredPropertyAssets![0].yearBuilt!,
                bed: filteredPropertyAssets![0].beds!,
                bath: filteredPropertyAssets![0].baths!,
                formattedAddress:
                  filteredPropertyAssets![0].address.formattedAddress!,
                latitude: filteredPropertyAssets![0].address.latitude!,
                longitude: filteredPropertyAssets![0].address.longitude!,
              }}
              blur
            />
          )} */}
        </Box>
        <Box
          pt="16px"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Pagination
            count={
              filteredPropertyAssets
                ? Math.ceil(filteredPropertyAssets.length / ASSETS_PER_PAGE)
                : 1
            }
            sx={{
              '& button': {
                fontFamily: 'Outfit!important',
              },
            }}
            defaultPage={1}
            siblingCount={1}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      </Grid>
    </SectionLayout>
  )
}

export default PortfolioDetailedAssetView
