import { formatIntegerDollars, formatNumberWithCommas } from '@flock/utils'
import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FLOCK_PHONE_NUMBER, PHONE_URL } from '../../constants'
import { LockFilledIcon } from '../icons/LockFilledIcon'
import { TrackedButton } from '../TrackedButton'

import {
  OvermoonAssetViewCardProps,
  overmoonTrackingName,
} from './assetViewCardTypes'

const OvermoonAssetViewCardDesktop = (props: OvermoonAssetViewCardProps) => {
  const { asset, processedImages, blur } = props

  return (
    <Paper
      sx={{
        borderRadius: '16px',
        padding: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '16px',
        position: blur ? 'relative' : undefined,
      }}
    >
      {blur && (
        <Box
          position="absolute"
          display="flex"
          width="564px"
          flexDirection="column"
          alignItems="center"
          padding={{ xs: '18px', sm: '32px' }}
          gap={{ xs: '8px', sm: '16px' }}
          sx={{
            background: 'rgba(255, 255, 255, 0.8)',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
            borderRadius: '24px',
            zIndex: 1,
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '64px',
          }}
        >
          <Box display="flex" gap="16px" alignItems="center">
            <Box width={{ xs: '32px', sm: '56px' }}>
              <LockFilledIcon width="28px" height="28px" />
            </Box>
            <Typography variant="h4">
              Detailed home view is exclusive content only for Flock Owners. To
              see more than a sneak peek:
            </Typography>
          </Box>
          <Box>
            <TrackedButton
              onClick={() => {
                window.open(PHONE_URL)
              }}
              trackingConfig={{ name: `${overmoonTrackingName}-close` }}
              variant="primary"
              sx={{ padding: '8px 32px' }}
            >
              <Typography variant="p3">Call {FLOCK_PHONE_NUMBER}</Typography>
            </TrackedButton>
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        gap="20px"
        width="100%"
        sx={{ filter: blur ? 'blur(8px)' : undefined }}
      >
        <Box display="flex" flexDirection="row" width="100%" gap="16px">
          <Box
            display="flex"
            justifyContent="center"
            width="464px"
            flexDirection="column"
            gap="16px"
          >
            <Box
              height="288px"
              sx={{
                borderRadius: '8px',
                position: 'relative',
              }}
            >
              <Box width="100%" height="288px" borderRadius="8px">
                <GatsbyImage
                  image={processedImages[0].childImageSharp.gatsbyImageData}
                  alt="Overmoon Phone Demo"
                  objectFit="cover"
                  style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                />
              </Box>{' '}
              <Paper
                sx={{
                  borderRadius: '8px',
                  width: 'fit-content',
                  height: '32px',
                  backgroundColor: 'lavender.main',
                  position: 'absolute',
                  top: '8px',
                  left: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  px: '8px',
                }}
              >
                <Typography
                  variant="cta"
                  color="white.main"
                >{`${formatIntegerDollars(
                  Math.round(asset.annualGrossRevenue),
                  true
                )} projected gross annual revenue`}</Typography>
              </Paper>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap="16px">
            <Box display="flex" gap="16px">
              <Box
                sx={{
                  borderRadius: '8px',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: '136px',
                  height: '136px',
                }}
              >
                <GatsbyImage
                  image={processedImages[1].childImageSharp.gatsbyImageData}
                  alt="Overmoon Phone Demo"
                  objectFit="cover"
                  style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                />
              </Box>
              <Box
                sx={{
                  borderRadius: '8px',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: '136px',
                  height: '136px',
                }}
              >
                <GatsbyImage
                  image={processedImages[2].childImageSharp.gatsbyImageData}
                  alt="Overmoon Phone Demo"
                  objectFit="cover"
                  style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                />
              </Box>
            </Box>
            <Box display="flex" gap="16px">
              <Box
                sx={{
                  borderRadius: '8px',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: '136px',
                  height: '136px',
                }}
              >
                <GatsbyImage
                  image={processedImages[3].childImageSharp.gatsbyImageData}
                  alt="Overmoon Phone Demo"
                  objectFit="cover"
                  style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                />
              </Box>
              <Box
                sx={{
                  borderRadius: '8px',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: '136px',
                  height: '136px',
                }}
              >
                <GatsbyImage
                  image={processedImages[4].childImageSharp.gatsbyImageData}
                  alt="Overmoon Phone Demo"
                  objectFit="cover"
                  style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography variant="p1" fontWeight="bold">
              {asset.street}
            </Typography>
            <Typography variant="p2">
              {asset.city}, {asset.state}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            gap="32px"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="p1">{asset.bed}</Typography>
              <Typography variant="p2">bed</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="p1">{asset.bath}</Typography>
              <Typography variant="p2">bath</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="p1">
                {asset.sqft ? formatNumberWithCommas(asset.sqft) : 'unknown'}
              </Typography>
              <Typography variant="p2">sq ft</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="p1">single-family home</Typography>
              <Typography variant="p2">built {asset.yearBuilt}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default OvermoonAssetViewCardDesktop
