export const formatNumberWithCommas = (value: number, decimals: number = 0) =>
  value.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const formatIntegerDollars = (
  dollars: number,
  dollarSign: boolean = true
) => {
  const formattedWithCommas = formatNumberWithCommas(dollars)
  if (dollarSign && dollars < 0) {
    return `-$${formattedWithCommas.substring(1)}`
  } else if (dollarSign) {
    return `$${formattedWithCommas}`
  }

  return formattedWithCommas
}

export const dollarsToInteger = (dollarString: string) =>
  parseInt(dollarString.replace(/,|\$/g, ''), 10)

export const formattedDollarsToCents = (dollarString: string) => {
  if (dollarString.includes('$')) {
    return Math.round(
      parseFloat(dollarString.substring(1).replace(/,/g, '')) * 100
    )
  } else {
    return Math.round(parseFloat(dollarString.replace(/,/g, '')) * 100)
  }
}

export const formatDollarsAndCents = (
  dollarsAndCents: number,
  dollarSign: boolean = true,
  useNegativeSign: boolean = false // use negative sign instead of parenthesis
) => {
  const formattedWithCommas = formatNumberWithCommas(dollarsAndCents, 2)

  if (dollarSign) {
    if (dollarsAndCents < 0) {
      if (useNegativeSign) {
        return `-$${formattedWithCommas.substring(1)}`
      }
      return `($${formattedWithCommas.substring(1)})`
    }
    return `$${formattedWithCommas}`
  } else if (dollarsAndCents < 0) {
    if (useNegativeSign) {
      return `-${formattedWithCommas.substring(1)}`
    }
    return `(${formattedWithCommas.substring(1)})`
  }

  return formattedWithCommas
}

export const formatIntegerCents = (
  cents: number,
  dollarSign: boolean = true
) => {
  const dollarsAndCents = cents / 100
  return formatDollarsAndCents(dollarsAndCents, dollarSign)
}

export const decimalToPercent = (decimal: number) => `${decimal * 100}%`

export const percentToDecimal = (percent: string) => parseFloat(percent) / 100.0
