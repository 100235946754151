export type OvermoonAssetViewCardProps = {
  asset: OvermoonAsset
  processedImages: {
    childImageSharp: {
      gatsbyImageData: any
    }
  }[]
  blur?: boolean
}

export type OvermoonAsset = {
  city: string
  state: string
  street: string

  formattedAddress: string
  latitude: number
  longitude: number

  yearBuilt: number
  bed: number
  bath: number
  sqft: number

  annualGrossRevenue: number

  primaryPhotoUrl: string
  photo2: string
  photo3: string
  photo4: string
  photo5: string
}

export const overmoonTrackingName = 'portfolio-featured-overmoon-homes'
