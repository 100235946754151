import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import theme from '@flock/shared-ui/src/theme/theme'
import { TrackedButton } from '@flock/shared-ui'
import PortfolioHeroImg from '../../images/portfolio-hero.webp'
import SectionLayout from '../SharedComponents/SectionLayout'
import { CALENDLY_LANDING_URL } from '../../constants'
import { useCalendly } from '../utils'
import ScheduleCall from '../SharedComponents/ScheduleCall'

type PortfolioHeroSectionProps = {
  propertyCount: string
}

const PortfolioHeroSection = (props: PortfolioHeroSectionProps) => {
  const { propertyCount } = props
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'portfolio',
    scheduleUrl: CALENDLY_LANDING_URL as string,
  })

  return (
    <>
      <ScheduleCall {...otherCalendlyProps} />

      <SectionLayout
        name="portfolio-hero"
        backgroundColor="midnightBlue.main"
        verticalPadding={isMobile ? '24px' : '80px'}
      >
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          justifyContent={isMobile || isTablet ? 'left' : 'center'}
        >
          <Box display="flex" flexDirection="column" gap="32px">
            <Box
              display="flex"
              flexDirection="column"
              alignItems={isMobile || isTablet ? 'left' : 'center'}
              gap="16px"
            >
              <Typography color="white.main" variant="h1">
                Overmoon&apos;s Portfolio
              </Typography>
              <Typography
                color="white.main"
                variant="p1"
                align={isMobile || isTablet ? 'left' : 'center'}
              >
                Discover diversification, institutional scale, and passive
                ownership through the Overmoon 721 Fund.
              </Typography>
            </Box>

            <Box display="flex" gap="32px">
              {!isMobile && !isTablet && (
                <Box width="832px" height="475px">
                  <Box
                    component="img"
                    src={PortfolioHeroImg}
                    width="832px"
                    height="475px"
                    borderRadius="12px"
                  />
                </Box>
              )}
              <Box
                display="flex"
                flexDirection={{
                  xs: 'column',
                  sm: 'row',
                  md: 'column',
                }}
                height={{ md: '475px' }}
                gap="32px"
              >
                <Box
                  sx={{
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: {
                      xs: '24px 24px',
                      sm: '8px 24px',
                    },
                    gap: '8px',
                    height: {
                      sm: '226px',
                    },
                    width: {
                      sm: '256px',
                    },
                    backgroundColor: 'lavender.main',
                  }}
                >
                  <Typography variant="h2" color="white.main">
                    {propertyCount}
                  </Typography>
                  <Typography
                    variant="p2"
                    color="white.main"
                    sx={{ lineHeight: '100%' }}
                  >
                    Overmoon operated homes
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="34px"
                  padding="24px"
                  borderRadius="12px"
                  sx={{ backgroundColor: 'white.main' }}
                >
                  <Typography variant={isMobile ? 'p2' : 'p0'}>
                    Want to know more about our portfolio?
                  </Typography>
                  <TrackedButton variant="primary" onClick={onOpenCalendly}>
                    Schedule a call
                  </TrackedButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </SectionLayout>
    </>
  )
}

export default PortfolioHeroSection
